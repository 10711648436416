import "./Header.scss";

function Header() {
  return (
    <div className="Header">
      <h1>Leon Dietsch</h1>
      <div className="Header__Subline">
        <h2>Audio-Produktion</h2>
        <a href="mailto:hey@leon.audio">hey@leon.audio</a>
      </div>
    </div>
  );
}

export default Header;
