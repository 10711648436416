import "./Footer.scss";

function Footer(props) {
  return (
    <footer className="Footer">
      <button onClick={props.toggleImpress}>Impressum & Datenschutz</button>
    </footer>
  );
}

export default Footer;
