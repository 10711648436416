import CanvasWaves from "canvas-waves";
import { useEffect, useRef } from "react";
import "./Wave.scss";

const wavesConfig = {
  color: "#ff00d5",
  baseHeight: "40%",
  waveHeight: "20%",
  wavesVisible: 4,
  nodeCount: 140,
  waveGrows: 2,
  speed: 12000 / 6,
};

const mediaPhoneHeight = () => window.matchMedia("(max-height:520px)");
const mediaPhoneWidth = () => window.matchMedia("(max-width:560px)");
const mediaAllowsMotion = () =>
  window.matchMedia("(prefers-reduced-motion: no-preference)");

function createWaveConfiguration() {
  let config = wavesConfig;

  if (mediaPhoneHeight().matches) {
    config = {
      ...config,
      baseHeight: "30%",
      waveHeight: "10%",
    };
  }

  if (mediaPhoneWidth().matches) {
    config = {
      ...config,
      wavesVisible: 2,
    };
  }

  return config;
}

function createWave(waveRef, recreate = false) {
  if (recreate) {
    waveRef.current.destroy();
    waveRef.current = null;
  }

  if (!waveRef.current) {
    mediaPhoneHeight().onchange = () => createWave(waveRef, true);
    mediaPhoneWidth().onchange = () => createWave(waveRef, true);

    waveRef.current = CanvasWaves.create(
      ".WaveContainer",
      createWaveConfiguration()
    );
  }

  const startStopAnimation = (resume) => {
    if (resume) {
      waveRef.current.resume();
    } else {
      waveRef.current.pause();
    }
  };

  mediaAllowsMotion().onchange = (e) => {
    startStopAnimation(e.matches);
  };

  startStopAnimation(mediaAllowsMotion().matches);
}

function Wave() {
  let waveRef = useRef();

  useEffect(() => {
    createWave(waveRef);
  });

  return (
    <div className="Wave">
      <div className="WaveContainer"></div>
    </div>
  );
}

export default Wave;
