import "./App.css";
import Wave from "./Wave";
import Header from "./Header";
import Footer from "./Footer";
import Impress from "./Impress";
import { useState } from "react";

function App() {
  const [impressVisible, setImpressVisible] = useState(false);

  const toggleImpress = () => {
    setImpressVisible(!impressVisible);
  };

  const closeImpress = () => {
    setImpressVisible(false);
  };

  return (
    <main className="App">
      <Header></Header>
      <Impress isVisible={impressVisible} close={closeImpress}></Impress>
      <Wave></Wave>
      <Footer toggleImpress={toggleImpress}></Footer>
    </main>
  );
}

export default App;
